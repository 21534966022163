export const REST_URL_BASE_AVAIL = "http://realtime.indygo.net/InfoPoint/rest";
export const REST_URL_BASE_SWIFTLY_INFO = "https://api.goswift.ly/info/indygo";
export const REST_URL_BASE_SWIFTLY_REALTIME = "https://api.goswift.ly/real-time/indygo";

export const GTFS_REFRESH_TIME = 60 * 60 * 24 * 1000; // 24 hours

export const LINE_DESCRIPTIONS = {
  RED_LINE: "Red Line",
  BLUE_LINE: "Blue Line",
  PURPLE_LINE: "Purple Line",
  GENERIC: "Bus"
};

export const RED_LINE_ALERTS = [90, 901, 902];

export const GHOST_BUSES = [1972, 1980];

export const LINE_COLORS = {
  RED_LINE: "D61328",
  PURPLE_LINE: "893EFF",
  BLUE_LINE: "316A8D",
  GENERIC: "61AE6C"
};

export const STATION_TYPES = {
  CURBSIDE: "Curbside",
  STATION: "Station",
  SUPER: "Super"
};

export const AVAIL_API_ERROR = "availApiError";
export const TOP_THREE_ALERTS = "topThreeAlerts";

export const CLOSED_STATIONS = [
  // 70004, // Kessler SB
  // 70005, // Kessler NB
  // 70006, // 54th SB
  // 70007, // 54th NB
  // 70014, // Park WB
  // 70015, // Park EB
  // 70008, // 52nd SB
  // 70009, // 52nd NB
  // 70010, // 46nd SB
  // 70011, // 46nd NB
  // 70012, // 42nd SB
  // 70013, // 42nd NB
  // 70016, // 38th Station SB
  // 70017, // 38th Station NB
  // 70018, // 34th Station SB
  // 70019, // 34th Station NB
  // 70020, // 30th Station SB
  // 70021, // 30th Station NB
  // 70024, // 22nd Station SB
  // 70025, // 22nd Station NB
  // 70028, // IU Health Station SB
  // 70029, // IU Health Station NB
  // 70038, // New Jersey SB
  // 70039, // New Jersey NB
];

// THESE ARE ALL THE CENTER STATIONS FOR THE REDLINE
export const RED_LINE_CENTER_STATION_IDS = [
  70002,
  70003,
  70004,
  70005,
  70006,
  70007,
  70008,
  70009,
  70010,
  70011,
  70012,
  70013,
  70014,
  70015,
  70016,
  70017,
  70018,
  70019,
  70020,
  70021,
  70022,
  70023,
  70024,
  70025,
  70026,
  70027,
  70028,
  70029,
  70030,
  70031,
  70032,
  70033,
  70034,
  70035,
  70036,
  70037,
  70052,
  70053
]

export const RED_LINE_STOP_IDS = [
  70001,
  70002,
  70003,
  70004,
  70005,
  70006,
  70007,
  70008,
  70009,
  70010,
  70011,
  70012,
  70013,
  70014,
  70015,
  70016,
  70017,
  70018,
  70019,
  70020,
  70021,
  70022,
  70023,
  70024,
  70025,
  70026,
  70027,
  70028,
  70029,
  70030,
  70031,
  70032,
  70033,
  70034,
  70035,
  70036,
  70037,
  70038,
  70039,
  70040,
  70041,
  70042,
  70043,
  70044,
  70045,
  70046,
  70047,
  70048,
  70049,
  70050,
  70051,
  70052,
  70053
];

export const SUPER_STOP_IDS = [
  51347,
  51355,
  52200,
];

export const STOP_IDS = [...RED_LINE_STOP_IDS, ...SUPER_STOP_IDS];

export const TRIGGERED_ANNOUNCEMENTS = [
  {
    label: "Take advantage of fare capping",
    name: "05-pay_fare-ctc_rl_stations"
  },
  {
    label: "Please keep your personal belongings with you at all times",
    name: "00-personal-belongings"
  },
  {
    label:
      "In the event of an emergency, please follow the directions of your operator",
    name: "00-in-the-event-of-an-emergency"
  },
  {
    label:
      "For the comfort of all riders, enjoy your music and phone calls at low volume and please use headphones",
    name: "00-use-headphones"
  },
  {
    label: "Due to a special event this station is closed",
    name: "00-special-event-closed"
  },
  {
    label:
      "IndyGo and local law enforcement reserve the right to ask you to leave this station if you are not adhering to IndyGo policies",
    name: "180-indygo-policies"
  },
  {
    label: "Mind the gap between the platform and the vehicle",
    name: "00-mind-the-gap"
  },
  {
    label: "Please help keep our facilities clean",
    name: "00-keep-facilities-clean"
  },
  {
    label: "This station is closed",
    name: "00-closed"
  }
];
