import { getLineIconUrl, writeLog } from "./utils";
import { ARRIVAL_SCREEN_SWIPER } from ".";

export interface Alert {
  Message: string;
  CauseReportLabel?: string;
  LineColor?: string;
  LineDescription?: string;
}

interface AlertsResponse {
  topThreeAlerts: Alert[];
  availApiError?;
}

function updateCard(card: HTMLElement, alert: Alert) {
  const logoElement = card.querySelector<HTMLImageElement>(".alert-card__logo");
  const messageElement = card.querySelector<HTMLElement>(".alert-card__text");
  const labelElement = card.querySelector<HTMLElement>(".alert-card__label");
  const iconElement = card.querySelector<HTMLElement>(".alert-card__icon");

  if (alert.CauseReportLabel === "UnknownCause") {
    alert.CauseReportLabel = "";
    iconElement.classList.add("hide");
  }
  else {
    iconElement.classList.remove("hide");
  }

  messageElement.innerText = alert.Message;
  labelElement.innerText = alert.CauseReportLabel;
  logoElement.src = getLineIconUrl(alert.LineDescription);
  card.style.setProperty("--line-color", `#${alert.LineColor}`);
}

export async function refresh(stopId: string) {
  writeLog("Refreshing alerts");
  const alerts = await fetchData(stopId);
  const alertsSlide = document.getElementById("alerts-slide");
  const alertsSlideIsVisible = !alertsSlide.classList.contains("hide")

  if (!alerts || alerts.length === 0) {
    if(alertsSlideIsVisible){
      alertsSlide.classList.add("hide");
      window[ARRIVAL_SCREEN_SWIPER].update()
    }
    return;
  }

  if(alerts.length > 0 && !alertsSlideIsVisible){
    alertsSlide.classList.remove('hide');
    window[ARRIVAL_SCREEN_SWIPER].update();
  }

  const alertCards = Array.from(
    document.querySelectorAll<HTMLElement>(".alert-card")
  );

  for (const [index, card] of alertCards.entries()) {
    const alert = alerts[index];

    if(alerts[index]) {
      card.classList.remove("invisible");
      updateCard(card, alert);
    } else {
      // if less than 3 alerts (ie: 1 or 2), 
      // it will set the opacity to 0 on the card
      card.classList.add("invisible");
    }
  }
}

async function fetchData(stopId: string) {
  try {
    const response = await fetch(`/stop/${stopId}/RefreshAlerts?t=${Date.now()}`);
    const data: AlertsResponse = await response.json();

    if (data.availApiError != null) {
      throw new Error(`Avail API error: ${data.availApiError}`);
    }

    return data.topThreeAlerts;
  } catch (error) {
    writeLog(`Exception occurred: alerts.ts, Error refreshing top three alerts, ${error}`)
    return [];
  }
}
