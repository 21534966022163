import indygoLogo from "./images/indygo-logo.svg";
export const writeLog = (msg: string) => process.stdout ? process.stdout.write(msg) : console.log(msg);

const SLIDE_UP_DURATION = 1000;
const SLIDE_UP_SPEED = 600;

export function slideUp(
  selector: string,
  updateFunc: (element: HTMLElement) => void
) {
  const element = document.querySelector<HTMLElement>(selector);
  const isLoading = document.body.classList.contains("loading");

  if (isLoading) {
    updateFunc(element);
    return;
  }

  const container = element.parentElement;

  const newElement = element.cloneNode(true) as HTMLElement;

  container.style.height = `${container.offsetHeight}px`;
  element.style.marginBottom = "2rem";

  updateFunc(newElement);

  container.appendChild(newElement);

  element.animate(
    [
      { marginTop: "0" },
      { marginTop: `calc(-${element.offsetHeight}px - 2rem)` }
    ],
    SLIDE_UP_SPEED
  );

  setTimeout(() => {
    container.style.height = null;
    container.removeChild(element);
  }, SLIDE_UP_DURATION);
}

export function getLineIconUrl(lineDescription: string) {
  switch (lineDescription) {
    case "Red Line":
      return indygoLogo;
    case "Purple Line":
      return indygoLogo;
    case "Blue Line":
      return indygoLogo;
    default:
      return indygoLogo;
  }
}
