import { setupBroadcastMsgCharacterCounter } from "./edit-stop";
export type BroadcastUpdateModel = Array<{ stationId: number }>;

export const setupBroadcastSteps = () => {
    setupBroadcastMsgCharacterCounter(240, true);
    const broadcastMsgContainer = document.querySelector("#step-broadcast-msg"),
        broadcastStationSelectionContainer = document.querySelector("#step-broadcast-stations"),
        broadcastDialogContainer = document.querySelector("#broadcast-confirm-post-dialog"),
        broadcastConfirmDialogMessage = document.querySelector("#confirm-message"),
        corridorAlertButton = document.querySelector("#broadcast-button-corridor"),
        stationAlertButton = document.querySelector("#broadcast-button-station"),
        broadcastPostButton = document.querySelector("#broadcast-button-post"),
        broadcastSubmitButton = document.querySelector("#broadcast-button-submit"),
        broadcastCancelButtonStepTwo = document.querySelector("#broadcast-button-cancel-step-two"),
        broadcastCancelButtonStepThree = document.querySelector("#broadcast-button-cancel-step-three"),
        broadcastCancelDialogButton = document.querySelector("#broadcast-button-dialog-cancel"),
        hiddenAlertTypeInput = document.querySelector("#AlertType"),
        hiddenStationsToUpdate = document.querySelector("#StationsToUpdate"),
        allStationInputs = document.querySelectorAll("input[id*='stop-check']");

        const stepOneErrorElement = document.querySelector("#broadcast-message-error"),
                stepTwoErrorElement = document.querySelector("#broadcast-station-error");


    let SelectedStations: BroadcastUpdateModel = new Array<{ stationId: number }>();

    if (allStationInputs && allStationInputs.length) {
        allStationInputs.forEach(function (input, idx) {
            input = <HTMLElement>input;
            input.addEventListener("click", function (e) {
                //accomodate for NB and SB - so, storing the stopId in id and name attributes
                const stationId = parseInt(input.id.replace(/stop-check/, ''), 10);
                const associatedStationId = parseInt(input.getAttribute("name").replace(/stop-check/, ''), 10);
                if (<HTMLElement><unknown>document.getElementById(input.id).checked) {
                    stepTwoErrorElement.innerHTML = '';
                    SelectedStations.push({ stationId });
                    SelectedStations.push({ stationId: associatedStationId });
                }
                else {
                    SelectedStations = SelectedStations.filter(item => item.stationId !== stationId && item.stationId !== associatedStationId);
                }
            });
        });
    }

    broadcastMsgContainer.classList.add("in");

    // corridor alert -> skip step 2, and update the alert type and dialog confirm message 
    corridorAlertButton.addEventListener("click", function (e) {
        if (!document.getElementById('broadcast-textarea').value) {
            stepOneErrorElement.innerHTML = 'Broadcast message field is required to post a broadcast alert.';
            return;
        } else {
            stepOneErrorElement.innerHTML = '';
        }
        broadcastMsgContainer.classList.remove("in");
        broadcastConfirmDialogMessage.innerHTML = 'This message will be sent to every station.';
        broadcastDialogContainer.classList.add("in");
        hiddenAlertTypeInput.value = "C";
    });

    // station alert -> show Station Selection, update alert type
    stationAlertButton.addEventListener("click", function (e) {
        const stepOneErrorElement = document.querySelector("#broadcast-message-error");
        if (!document.getElementById('broadcast-textarea').value) {
            stepOneErrorElement.innerHTML = 'Broadcast message field is required to post a broadcast alert.';
            return;
        } else {
            stepOneErrorElement.innerHTML = '';
        }
        broadcastMsgContainer.classList.remove("in");
        broadcastStationSelectionContainer.classList.add("in");

        hiddenAlertTypeInput.value = "S";
    });

    // go to step 3 from step 2, Station Selection
    // change dialog confirmation message
    broadcastPostButton.addEventListener("click", function (e) {
        if (!SelectedStations.length) {
            stepTwoErrorElement.innerHTML = 'Atleast one station is required to be selected to post a broadcast message.';
            return;
        } else {
            stepTwoErrorElement.innerHTML = '';
        }

        broadcastStationSelectionContainer.classList.remove("in");
        broadcastConfirmDialogMessage.innerHTML = 'This message will be sent to selected stations.';
        broadcastDialogContainer.classList.add("in");
    });

    /// cancel and go back to step 2, Station Selection
    broadcastCancelButtonStepThree.addEventListener("click", function (e) {
        broadcastDialogContainer.classList.remove("in");
        if (hiddenAlertTypeInput.value === "S")
            broadcastStationSelectionContainer.classList.add("in");
        else
            broadcastMsgContainer.classList.add("in");
    });

    /// cancel and go back to step 1, Broadcast Message
    broadcastCancelButtonStepTwo.addEventListener("click", function (e) {
        broadcastStationSelectionContainer.classList.remove("in");
        broadcastMsgContainer.classList.add("in");
    });

    /// close dialog and back to step 2
    broadcastCancelDialogButton.addEventListener("click", function (e) {
        broadcastDialogContainer.classList.remove("in");
        if (hiddenAlertTypeInput.value === "S")
            broadcastStationSelectionContainer.classList.add("in");
        else
            broadcastMsgContainer.classList.add("in");
    });

    broadcastSubmitButton.addEventListener("click", function (e) {
        // concatenate id string that will be posted via the requests body,
        // that the api will have logic to split into an array,
        // and update all selected stops.
        let idString = "";
        for (const item of SelectedStations)
            idString += item == SelectedStations[SelectedStations.length - 1] ? item.stationId : `${item.stationId},`;

        hiddenStationsToUpdate.value = idString;
    });
}
