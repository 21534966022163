export function updateVolumeLabel() {
  const volumeSlider = document.getElementById("speaker-volume-slider") as HTMLInputElement;
  const volumeLabel = document.getElementById("speaker-volume-label") as HTMLElement;
  const infoButton = document.getElementById("info-button") as HTMLButtonElement;

  document
    .getElementById("speaker-volume-slider")
    .addEventListener("input", () => {
      volumeLabel.innerHTML = volumeSlider.value;
      infoButton.disabled = false;
    });
}


export function setupBroadcastMsgCharacterCounter(maxCount = 240, showCounterAsCharsRemaining = false) {
  const broadcast = document.getElementById("broadcast-textarea"),
    currentCharacterDiv = document.getElementById("current-character-count-div"),
    broadcastMessageError = document.getElementById("broadcast-message-error"),
    max = maxCount,
    html = broadcast.value,
    showCharsRemaining = showCounterAsCharsRemaining;

  let currentCharCount = 0;
  broadcast.addEventListener("input", (e) => {
    const html = document.getElementById("broadcast-textarea").value;
    if (html && html.length && html.length <= max) {
      currentCharCount = broadcast.value.length;
    } else if (!html && html.length == 0) {
      currentCharCount = 0;
    } else {
      currentCharCount = max;
    }

    if (currentCharCount > 0 && !!broadcastMessageError) 
        broadcastMessageError.innerHTML = '';

    currentCharacterDiv.innerHTML = !!showCharsRemaining ? `${240 - currentCharCount} Characters Remaining` : `${currentCharCount}/240`;
  });

  currentCharacterDiv.innerHTML = !!showCounterAsCharsRemaining ? `${240 - html.length} Characters Remaining` : `${html.length}/240`;
}
export function handleFileUpload() {
  const upload = document.getElementById("upload-input") as HTMLElement;
  const uploadButton = document.getElementById("upload-button") as HTMLButtonElement;
  const uploadButtonText = document.getElementById("upload-button-text") as HTMLButtonElement;
  const uploadButtonLoader = document.getElementById("upload-button-spinner") as HTMLButtonElement;

  const imagePreview = document.querySelector(".form__image-preview") as HTMLElement;

  upload.addEventListener("input", e => {
    const target = e.target as HTMLInputElement;
    const previewImage = window.URL.createObjectURL(target.files[0]);

    imagePreview.style.backgroundImage = `url(${previewImage})`;

    setTimeout(() => {
      uploadButton.disabled = false;
    }, 400);
  });

  uploadButton.addEventListener("click", e => {
    uploadButtonText.classList.add("hide");
    uploadButtonLoader.classList.remove("hide");
  })
}
